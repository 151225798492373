"use client";

import { useLocale } from "next-intl";
import type { ChangeEventHandler } from "react";

import { usePathname, useRouter } from "@/libs/i18nNavigation";
import { AppConfig } from "@/utils/AppConfig";
import { Select, SelectItem } from "@nextui-org/react";
import capitalize from "@/utils/capitalize";
import { locales } from "@/utils/locales";

export default function LocaleSwitcher(props: { value?: string}) {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (locale != event.target.value) {
      router.push(pathname, { locale: event.target.value });
      router.refresh();
    }
  };

  return (
    <div className="flex flex-row ">
      <Select
        defaultSelectedKeys={[locale]}
        size="sm"
        classNames={{
          label: "hidden",
          mainWrapper: "!bg-black flex gap-0 md:gap-3",
          trigger: "!bg-black !text-white ",
          value: `text-white text-[14px] md:text-[16px] ${props?.value}`,
          innerWrapper: "w-fit",
          selectorIcon: "px-0 mx-0 hidden ",
          listboxWrapper: "w-fit",
          popoverContent: "w-[100px]",
        }}
        disallowEmptySelection={true}
        onChange={handleChange}
        listboxProps={{
          itemClasses: {
            base: ["px-4"],
          },
        }}
      >
        {AppConfig.locales.map((elt: keyof typeof locales) => (
          <SelectItem
            key={elt}
            value={elt}
            className=" font-degular-display-semibold"
          >
            {capitalize(locales[elt])}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
}
