import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/travelthru.com/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/components/LocaleSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/templates/BaseTemplate/HeaderLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/travelthru.com/src/templates/BaseTemplate/MobileNav.tsx");
